import React, { useState, useEffect } from "react";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import {
  FaSquare,
  FaBuilding,
  FaRegObjectGroup,
  FaWhatsapp,
  FaHeartbeat,
  FaUsers,
  FaNewspaper,
  FaHistory,
  FaMapMarkedAlt,FaFileArchive
} from "react-icons/fa";
import { MdElectricalServices, MdPublish } from "react-icons/md";
import { SiMarketo } from "react-icons/si";
import BannerVideo from "../../assets/menemen.mp4";
import baskanImage from "../../assets/baskan.png"
import baskanIsmi  from '../../assets/baskanismi.png'

const Home = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const imageInterval = setInterval(() => {
      nextSlide();
    }, 5000);
    return () => clearInterval(imageInterval);
  }, [currentIndex]);

  const slides = [
    {
      url: "https://www.menemen.bel.tr/images/banner/gediz-nehri202241214364993.jpg",
      text: "Menemen için yorulmadan çalışacağız.",
    },
    {
      url: "https://www.menemen.bel.tr/images/banner/comlek-yapimi2019924101715289.jpg",
      text: "İkinci resim açıklaması",
    },
    {
      url: "https://www.menemen.bel.tr/images/banner/kubilay201999124621568.jpg",
      text: "Üçüncü resim açıklaması",
    },
  ];
  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };
  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };
  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };
  return (
    <div>
      <div>
        <div className="relative">
          <div>
            <video
              src={BannerVideo}
              className="w-full h-[900px] object-cover"
              alt="Menemen Belediyesi"
              autoPlay
              loop
              muted
            />
            <div className="absolute -bottom-[60px] left-[80%]">
              <img src={baskanImage} width={300} />
              <img src={baskanIsmi} width={300} className="ml-8"/>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-row bg-[#f8f9fa] mx-3.5">
        <div className="flex flex-col mt-9">
          <div className="font-bold text-3xl">Haberler</div>
          <img
            src={slides[currentIndex].url}
            className="w-[1240px] h-[640px] rounded-2xl object-cover duration-500"
            alt="Slide"
          />
          <div className="absolute -bottom-[600px] text-white font-semibold text-xl  ml-3 bg-homeImageTextContainer p-2 pr-28 rounded-md w-2/6 opacity-80">
            {slides[currentIndex].text}
          </div>
          <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
            <BsChevronCompactLeft onClick={prevSlide} size={30} />
          </div>
          <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
            <BsChevronCompactRight onClick={nextSlide} size={30} />
          </div>
          <div className="flex top-4 justify-start py-2 ">
            {slides.map((slide, slideIndex) => (
              <div
                key={slideIndex}
                onClick={() => goToSlide(slideIndex)}
                className={`text-xl cursor-pointer ${
                  currentIndex === slideIndex ? "text-gray-500" : "text-slate-300"
                }`}
              >
                <FaSquare />
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col justify-center mt-8">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3 mx-3 py-10 items-stretch">
            <div>
              <a
                href="https://e-hizmet.menemen.bel.tr/#/home"
                className="flex flex-col justify-between items-center border-2 border-customNavbarTextColor p-7 text-customNavbarTextColor  rounded-md lg:min-h-[200px] h-auto hover:bg-customNavbarTextColor transition duration-300 hover:text-white"
              >
                <FaBuilding fontSize={50} />
                <div className="text-xl font-semibold">E-Belediye</div>
              </a>
            </div>

            <div>
              <a
                href="https://www.menemen.bel.tr/imarplanlari"
                className="flex flex-col justify-between items-center border-2 border-customNavbarTextColor p-7 text-customNavbarTextColor rounded-md lg:min-h-[200px] h-auto hover:bg-customNavbarTextColor transition duration-300 hover:text-white"
              >
                <FaRegObjectGroup fontSize={50} />
                <div className="text-xl font-semibold">İmar Planları</div>
              </a>
            </div>

            <div>
              <a
                href="https://api.whatsapp.com/send?phone=905524448008&text=Merhaba%20Menemen%20Belediyesi"
                className="flex flex-col justify-between items-center border-2 border-customNavbarTextColor p-7 text-customNavbarTextColor rounded-md lg:min-h-[200px] h-auto hover:bg-customNavbarTextColor transition duration-300 hover:text-white"
              >
                <FaWhatsapp fontSize={50} />
                <div className="text-xl font-semibold">Him & Whatsapp Hattı</div>
              </a>
            </div>

            <div>
              <a
                href="https://www.menemen.bel.tr/menemen-nobetci-eczane"
                className="flex flex-col justify-between items-center border-2  border-customNavbarTextColor p-7 text-customNavbarTextColor rounded-md lg:min-h-[200px] h-auto hover:bg-customNavbarTextColor transition duration-300 hover:text-white"
              >
                <FaHeartbeat fontSize={50} />
                <div className="text-xl font-semibold">Nöbetçi Eczane</div>
              </a>
            </div>

            <div>
              <a
                href="https://www.menemen.bel.tr/ImgListe/212/acil-toplanma-alanlari"
                className="flex flex-col justify-between items-center border-2 border-customNavbarTextColor p-7 text-customNavbarTextColor rounded-md lg:min-h-[200px] h-auto hover:bg-customNavbarTextColor transition duration-300 hover:text-white"
              >
                <FaUsers fontSize={50} />
                <div className="text-xl font-semibold">Acil Toplanma Alanları & İrap</div>
              </a>
            </div>

            <div>
              <a
                href="https://www.menemen.bel.tr/ihale-arsivi"
                className="flex flex-col justify-between items-center border-2 border-customNavbarTextColor p-7 text-customNavbarTextColor items-center justify-between rounded-md lg:min-h-[200px] h-auto hover:bg-customNavbarTextColor transition duration-300 hover:text-white"
              >
                <FaNewspaper fontSize={50} />
                <div className="text-xl font-semibold">İhaleler</div>
              </a>
            </div>

            <div>
              <a
                href="https://www.menemen.bel.tr/ImgListe/6/hizmetlerimiz"
                className="flex flex-col justify-between items-center border-2 border-customNavbarTextColor p-7 text-customNavbarTextColor items-center justify-between rounded-md lg:min-h-[200px] h-auto hover:bg-customNavbarTextColor transition duration-300 hover:text-white"
              >
                <MdElectricalServices fontSize={50} />
                <div className="text-xl font-semibold">Sosyal Hizmetler</div>
              </a>
            </div>

            <div>
              <a
                href="https://www.menemen.bel.tr/ImgListe/9/menemen-tarihi-degerleri"
                className="flex flex-col justify-between items-center border-2 border-customNavbarTextColor p-7 text-customNavbarTextColor items-center justify-between rounded-md lg:min-h-[200px] h-auto hover:bg-customNavbarTextColor transition duration-300 hover:text-white"
              >
                <FaHistory fontSize={50} />
                <div className="text-xl font-semibold">Tarihi Değerler</div>
              </a>
            </div>

            <div>
              <a
                href="https://www.menemen.bel.tr/menemen-harita"
                className="flex flex-col justify-between items-center border-2 border-customNavbarTextColor p-7 text-customNavbarTextColor items-center justify-between rounded-md lg:min-h-[200px] h-auto hover:bg-customNavbarTextColor transition duration-300 hover:text-white"
              >
                <FaMapMarkedAlt fontSize={50} />
                <div className="text-xl font-semibold">Menemen İlçe Haritası</div>
              </a>
            </div>

            <div>
              <a
                href="https://www.menemen.bel.tr/yayin-arsivi"
                className="flex flex-col justify-between items-center border-2 border-customNavbarTextColor p-7 text-customNavbarTextColor items-center justify-between rounded-md lg:min-h-[200px] h-auto hover:bg-customNavbarTextColor transition duration-300 hover:text-white"
              >
                <MdPublish fontSize={50} />
                <div className="text-xl font-semibold">Yayınlar</div>
              </a>
            </div>
            <div>
              <a
                href="https://www.menemen.bel.tr/video-arsivi"
                className="flex flex-col justify-between items-center border-2 border-customNavbarTextColor p-7 text-customNavbarTextColor items-center justify-between rounded-md lg:min-h-[200px] h-auto hover:bg-customNavbarTextColor transition duration-300 hover:text-white"
              >
                <FaFileArchive fontSize={50} />
                <div className="text-xl font-semibold">Arşivler</div>
              </a>
            </div>
            <div>
              <a
                href="https://www.menemen.bel.tr/ImgListe/44/pazar-yerlerimiz"
                className="flex flex-col justify-between items-center border-2 border-customNavbarTextColor p-7 text-customNavbarTextColor items-center justify-between rounded-md lg:min-h-[200px] h-auto hover:bg-customNavbarTextColor transition duration-300 hover:text-white"
              >
                <SiMarketo fontSize={50} />
                <div className="text-xl font-semibold">Pazar Yerleri</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
